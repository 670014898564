<template>
  <div>
    <!-- 搜索筛选 -->
    <a-card style="margin-bottom: 24px">
      <a-form layout="inline">
        <a-row :gutter="5" style="line-height: 3.5">
          <a-col :md="8" :sm="24">
            <a-form-item label="门店编号：">
              <a-input
                style="overflow: hidden; text-overflow: ellipsis"
                v-model="queryParam.facilityCode"
                placeholder="请输入"
                @pressEnter="initTable"
              />
            </a-form-item>
          </a-col>
          <a-col :md="8" :sm="24">
            <a-form-item label="门店名称：">
              <a-input
                style="overflow: hidden; text-overflow: ellipsis"
                v-model="queryParam.facilityName"
                placeholder="请输入"
                @pressEnter="initTable"
              />
            </a-form-item>
          </a-col>
          <a-col :md="8" :sm="24">
            <a-form-item label="档口编号：">
              <a-input
                style="overflow: hidden; text-overflow: ellipsis"
                v-model="queryParam.kitchenCode"
                placeholder="请输入"
                @pressEnter="initTable"
              />
            </a-form-item>
          </a-col>
          <a-col :md="8" :sm="24">
            <a-form-item label="店铺名称：">
              <a-input
                style="overflow: hidden; text-overflow: ellipsis"
                v-model="queryParam.storeName"
                placeholder="请输入"
                @pressEnter="initTable"
              />
            </a-form-item>
          </a-col>
          <a-col :md="8" :sm="24">
            <a-form-item label="入驻进度：">
              <a-select
                style="width: 175px"
                v-model="queryParam.processStatus"
                placeholder="请选择"
                allowClear
                @change="initTable"
              >
                <a-select-option v-for="item in processStatus" :key="item.id">{{ item.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="8" :sm="24">
            <a-form-item>
              <a-button @click="resetQueryParam">重置</a-button>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-card>
    <!-- 列表 -->
    <a-spin :spinning="dataLoading">
      <a-card>
        <a-row :gutter="0" style="line-height: 3">
          <a-table
            rowKey="id"
            :columns="tableColumns"
            :dataSource="resRows"
            :pagination="pagination"
            @change="tableChanged"
          >
            <span slot="action" slot-scope="text, record" class="blueColor">
              <a @click="handleDetails(record)">查看</a>
            </span>
          </a-table>
        </a-row>
      </a-card>
    </a-spin>
  </div>
</template>

<script>
  
import axios from "axios";
import Bus from "@/bus";
import { defaultPagination } from "@/utils/pagination";
import { mapGetters } from "vuex";
import { tenantEnteringStatus } from "@/objects/businessObjects";

const datastore = [];
export default {
  name: "tenantEnteringList",
  data() {
    return {
      queryParam: {
        facilityCode: "",
        facilityName: "",
        kitchenCode: "",
        storeName: "",
        processStatus: undefined,
      },
      pagination: defaultPagination(() => {}),
      processStatus: tenantEnteringStatus,
      // 列表api返回的列表行映射到本地的状态
      resRows: [],
      dataLoading: false,
      // 表头
      tableColumns: [
        {
          title: "门店编号",
          dataIndex: "facilityCode",
          key: "facilityCode",
        },
        {
          title: "门店名称",
          dataIndex: "facilityName",
          key: "facilityName",
        },
        {
          title: "档口编号",
          dataIndex: "kitchenCode",
          key: "kitchenCode",
        },
        {
          title: "店铺名称",
          dataIndex: "storeName",
          key: "storeName",
        },
        {
          title: "城市",
          dataIndex: "cityName",
          key: "cityName",
        },
        {
          title: "厨房品牌",
          dataIndex: "brandName",
          key: "brandName",
        },
        {
          title: "入驻进度",
          dataIndex: "processStatusName",
          key: "processStatusName",
        },
        {
          title: "总耗时",
          dataIndex: "consumeTime",
          key: "consumeTime",
        },
        {
          title: "操作",
          key: "action",
          width: "80px",
          scopedSlots: { customRender: "action" },
        },
      ],
    };
  },
  created() {
    this.init();
    Bus.$on("getTarget", (target) => {
      this.init();
    });
  },
  computed: {
    ...mapGetters("users", ["authenticatedUser"]),
  },
  methods: {
    init(pageNumber) {
      this.dataLoading = true;
      axios({
        url:   this.$baseUrl + "merchant/entering/list",
        method: "POST",
        withCredentials: true,
        data: {
          page: pageNumber || 1,
          rows:
            this.pagination.pageSize || this.pagination.defaultPageSize || 20,
          ...this.queryParam,
        },
      }).then((res) => {
        if (res.data.rows) {
          this.resRows = res.data.rows;
          const pagination = { ...this.pagination };
          pagination.total = res.data.count;
          pagination.current = pageNumber || 1;
          this.pagination = pagination;
        }
        this.dataLoading = false;
      });
    },
    // 重置页面
    resetQueryParam() {
      this.queryParam = {};
      this.resRows = [];
      this.initTable();
    },
    initTable() {
      this.resRows = [];
      this.pagination.current = 1;
      this.pagination.total = 0;
      this.init();
    },
    tableChanged(val) {
      this.init(val.current);
    },
    // 详情页 - 查看
    handleDetails(val) {
      this.$router.push({
        path: "/store/tenantEnterList/tenantEnterDetail",
        query: { id: val.id },
      });
    },
  },
};
</script>

<style scoped>
</style>
